import React from 'react';

import Team from '../components/team/team';
import Layout from '../components/layout';
import SEO from '../components/seo';
import settings from '../../settings';
import ImageBackground from "../components/imagebackground";

const banner =`${settings.IMAGES_BASE_URL}/v2/images/faq-banner.jpg`;

const InstructorsPage = () => {
  return (
    <Layout>
    <SEO title="BMW Performance Driving School | Meet Your Instructors" description="From the professional racing tracks to the BMW Performance Driving Center, these instructors make everyone a better driver. Talk about experience!" />
    <div className="instructors-page">
      <ImageBackground
        imageDesktop={banner}
        imageMobile={banner}
        imageAlt="press"
        imageClassName="banner"
        topLeftChildren={
          <div className="text__left">
            <h3>MEET YOUR INSTRUCTORS</h3>
          </div>
        }
        hideArrow
      />
      <div className="container"><Team /></div>
    </div>
  </Layout>
  );
}

export default InstructorsPage;