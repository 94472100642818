import React from 'react';
// import Popup from 'reactjs-popup';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import settings from '../../../settings';


// const arrowleft = `${settings.IMAGES_BASE_URL}/v2/images/arrow-left.svg`;
// const arrowright = `${settings.IMAGES_BASE_URL}/v2/images/arrow-right.svg`;
// const closeIcon = `${settings.IMAGES_BASE_URL}/v2/images/close_btn_black.svg`;
class TeamMember extends React.Component {
  state = {
    width: '',
    show: false,
    isExpanded: false
  };

  onExpanded = () => [ this.setState({ isExpanded: !this.state.isExpanded }) ];

  componentDidMount() {
    this.setState({
      width: window.innerWidth
    });
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  handleCardDisplay = () => {
    const currentState = this.state.show;
    this.setState({ show: !currentState });
  };

  render() {
    const { width } = this.state;
    const isMobile = width < 1200;
    const isDesktop = width >= 1200;

    return (
      <React.Fragment>
        {isMobile && (
          <div className="teamaccordion__item">
            <div className={`teamaccordion__card ${this.state.isExpanded ? 'is-expanded' : ' '} `}>
              <button className="teamaccordion__card__header" onClick={this.onExpanded}>
                <h5>{this.props.name}</h5>
              </button>
              <div className="teamaccordion__card__content">
                <LazyLoadImage effect="blur" src={this.props.image} alt={this.props.Name + ' sml'} />
                <p>{this.props.title}</p>
                <p>{this.props.introduction}</p>
              </div>
            </div>
          </div>
        )}
        {isDesktop && (

          <div className="team__item">
            <div className="team__item__image">
              <LazyLoadImage effect="blur" src={this.props.image} alt={this.props.Name + ' sml'} />
            </div>
            <div className="team__item__content">
              <p>{this.props.name}</p>
              <span>{this.props.title}</span>
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
}
export default TeamMember;
